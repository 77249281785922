import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { motion } from 'framer-motion';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line
import Layout from '@components/layout';
import { Arrow } from '@svg/arrow';

const BlogPortal = ({ pageContext, data: { posts } }) => {
  const sortedPosts = posts.nodes;
  const otherPosts = sortedPosts.slice(1, 999);
  const otherPostsMobile = sortedPosts.slice(0, 999);

  const [hover, setHover] = useState(false);
  const [otherHover, setOtherHover] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.innerWidth < 1024 && setIsMobile(true);
  }, []);

  let arrowVars = {};

  if (!isMobile) {
    arrowVars = {
      initial: {
        x: -20,
        transition: {
          duration: 0.3,
        },
      },
      animate: {
        x: 0,
        transition: {
          duration: 0.3,
        },
      },
    };
  }

  return (
    <>
      <style>
        {`\
                .news-rollover:hover{\
                    background-color: ${sortedPosts[0].blogColor.hex}\
                }\
                .news-rollover:hover .rollover-category{\
                    color: #000 !important;\
                }\
            `}
      </style>

      <Layout>
        <div className='pt-28 md:pt-64 mb-28 lg:mb-40 grid grid-cols-14'>
          <div className='col-start-2 col-end-14'>
            <h1 className='title-block'>News & Reviews</h1>
          </div>

          {/* Featured Blog */}
          <div
            key={sortedPosts[0].title}
            className='hidden lg:grid news-rollover transition duration-300 ease-in-out col-start-1 col-end-15 mt-12 grid-cols-14 items-center justify-center mx-halfGutter'>
            <div className='w-full my-12 col-start-2 col-end-14 lg:col-end-8 mr-halfGutter -ml-halfGutter overflow-hidden'>
              {sortedPosts[0].external ? (
                <a
                  href={sortedPosts[0].external}
                  target='_blank'
                  rel='noreferrer'>
                  <Image
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className='transform hover:scale-105 transition duration-300 ease-in-out'
                    {...sortedPosts[0].featuredImage.image}
                  />
                </a>
              ) : (
                <Link to={`/news/${sortedPosts[0].slug.current}`}>
                  <Image
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className='transform hover:scale-105 transition duration-300 ease-in-out'
                    {...sortedPosts[0].featuredImage.image}
                  />
                </Link>
              )}
            </div>

            <div className='w-full col-start-2 lg:col-start-8 col-end-14'>
              <p className='text-xl leading-xl text-grey rollover-category transition duration-300 ease-in-out'>
                {sortedPosts[0].category} - {sortedPosts[0].date}
              </p>
              <h3 className='text-5xl leading-5xl font-medium mt-6'>
                {sortedPosts[0].title}
              </h3>
              <p className='text-base leading-base pt-12 mt-16 border-t border-grey md:text-lg md:leading-lg'>
                {sortedPosts[0].excerpt}
              </p>
              <div className=' mt-10'>
                {sortedPosts[0].external ? (
                  <>
                    <a
                      href={sortedPosts[0].external}
                      target='_blank'
                      rel='noreferrer'
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      className='font-semibold mt-0-important text-lg leading-lg w-full flex flex-row justify-between items-center'>
                      Read article
                      <motion.span
                        variants={arrowVars}
                        initial='initial'
                        animate={hover ? 'animate' : 'initial'}>
                        <Arrow />
                      </motion.span>
                    </a>
                  </>
                ) : (
                  <>
                    <Link
                      to={`/news/${sortedPosts[0].slug.current}`}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      className='font-semibold mt-0-important text-lg leading-lg w-full flex flex-row justify-between items-center'>
                      Read article
                      <motion.span
                        variants={arrowVars}
                        initial='initial'
                        animate={hover ? 'animate' : 'initial'}>
                        <Arrow />
                      </motion.span>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Other Blogs */}
          <div className='hidden lg:grid col-start-2 col-end-14 grid-cols-1 lg:grid-cols-3 lg:gap-x-16 gap-y-8 lg:gap-y-32 mt-40'>
            {otherPosts.map((post, i) => {
              const { title, category, featuredImage, slug, external } =
                post || {};
              return (
                <div className='text-black' key={i}>
                  <div className='overflow-hidden mb-5'>
                    {external ? (
                      <a href={external} target='_blank' rel='noreferrer'>
                        <Image
                          onMouseEnter={() => setOtherHover(i)}
                          className='object-cover top-0 left-0 hover:scale-105 transform duration-300 ease-in-out'
                          {...featuredImage.image}
                        />
                      </a>
                    ) : (
                      <Link to={`/news/${slug.current}`}>
                        <Image
                          onMouseEnter={() => setOtherHover(i)}
                          className='object-cover top-0 left-0 hover:scale-105 transform duration-300 ease-in-out'
                          {...featuredImage.image}
                        />
                      </Link>
                    )}
                  </div>
                  <span className='text-base leading-base text-grey'>
                    {category}
                  </span>
                  <h3 className='text-3xl leading-3xl font-medium'>{title}</h3>
                  <div className=' mt-4'>
                    {external ? (
                      <>
                        <a
                          href={external}
                          target='_blank'
                          rel='noreferrer'
                          onMouseEnter={() => setOtherHover(i)}
                          onMouseLeave={() => setOtherHover(false)}
                          to={`/news/${slug.current}`}
                          className='font-semibold mt-0-important text-lg leading-lg w-full flex flex-row justify-between items-center'>
                          Read article
                          <motion.span
                            variants={arrowVars}
                            initial='initial'
                            animate={otherHover === i ? 'animate' : 'initial'}>
                            <Arrow white={false} />
                          </motion.span>
                        </a>
                      </>
                    ) : (
                      <>
                        <Link
                          onMouseEnter={() => setOtherHover(i)}
                          onMouseLeave={() => setOtherHover(false)}
                          to={`/news/${slug.current}`}
                          className='font-semibold mt-0-important text-lg leading-lg w-full flex flex-row justify-between items-center'>
                          Read article
                          <motion.span
                            variants={arrowVars}
                            initial='initial'
                            animate={otherHover === i ? 'animate' : 'initial'}>
                            <Arrow white={false} />
                          </motion.span>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className='grid lg:hidden col-start-2 col-end-14 grid-cols-1 gap-y-16 mt-8 lg:mt-16'>
            {otherPostsMobile.map((post, i) => {
              const { title, category, featuredImage, slug, external } =
                post || {};
              return (
                <div className='text-black' key={i}>
                  <div className='overflow-hidden mb-5'>
                    {external ? (
                      <a href={external} target='_blank' rel='noreferrer'>
                        <Image
                          className='object-cover top-0 left-0 hover:scale-105 transform duration-300 ease-in-out'
                          {...featuredImage.image}
                        />
                      </a>
                    ) : (
                      <Link to={`/news/${slug.current}`}>
                        <Image
                          className='object-cover top-0 left-0 hover:scale-105 transform duration-300 ease-in-out'
                          {...featuredImage.image}
                        />
                      </Link>
                    )}
                  </div>
                  <span className='text-sm leading-sm md:text-base md:leading-base text-grey'>
                    {category}
                  </span>
                  <h3 className='text-xl leading-xl md:text-3xl md:leading-3xl font-medium'>
                    {title}
                  </h3>
                  <div className='mt-2 lg:mt-4'>
                    {external ? (
                      <>
                        <a
                          href={external}
                          target='_blank'
                          rel='noreferrer'
                          className='font-semibold mt-0-important text-sm leading-sm md:text-lg md:leading-lg w-full flex flex-row justify-between items-center'>
                          Read article
                          <Arrow white={false} />
                        </a>
                      </>
                    ) : (
                      <>
                        <Link
                          to={`/news/${slug.current}`}
                          className='font-semibold mt-0-important text-sm leading-sm md:text-lg md:leading-lg w-full flex flex-row justify-between items-center'>
                          Read article
                          <Arrow white={false} />
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogPortal;

export const blogQuery = graphql`
  query blogPortalQuery($skip: Int!, $limit: Int!) {
    posts: allSanityBlog(
      skip: $skip
      limit: $limit
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        excerpt
        date(locale: "NZ", formatString: "DD MMM YYYY")
        featuredImage {
          image {
            ...ImageWithPreview
          }
        }
        blogColor {
          hex
        }
        category
        content: _rawContent(resolveReferences: { maxDepth: 100 })
        title
        slug {
          current
        }
        external
      }
    }
  }
`;
